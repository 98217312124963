<template>
  <modal-form
    id="edit-invoice-form"
    lazy-validation
    :loading="loading"
    :fields="fields.formData"
    :value="value"
  >
    <template #header>
      {{ $t('invoice.editModal.title') }}
    </template>

    <template #fields>
      <InputFields :form-data="formData" :key="key" />
    </template>

    <template #form-actions>
      <hub-button name="cancel" variant="secondary" @click="$emit('close')" id="cancelButton">
        {{ $t('common.cancel') }}
      </hub-button>
      <hub-button name="save" @click="onSubmit" id="save-button" :loading="loading">
        {{ $t('common.save') }}
      </hub-button>
    </template>
  </modal-form>
</template>

<script>
import InputFields from './InputFields.vue'

export default {
  name: 'InvoiceEditModal',
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    selected: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  components: {
    InputFields,
  },
  data() {
    return {
      formData: {
        name: '',
        description: '',
        client_id: '',
        invoiced_at: '',
        date_from: '',
        date_to: '',
        currency: '',
        status: '',
      },
    }
  },
  computed: {
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: '/invoice/' + this.selected?.id,
      })
    },

    fields() {
      return {
        formData: {
          name: this.selected.attributes.name,
          client_id: this.selected.attributes.clientId,
          description: this.selected.attributes.description,
          invoiced_at: this.selected.attributes.invoicedAt,
          date_from: this.selected.attributes.dateFrom,
          date_to: this.selected.attributes.dateTo,
          currency: this.selected.attributes.currency,
          status: this.selected.attributes.status,
        },
      }
    },

    key() {
      return this.selected ? this.selected.id : 'none'
    },
  },
  methods: {
    async onSubmit() {
      const res = await this.$store.dispatch('invoice/update', {
        id: this.selected?.id,
        data: {
          description: this.formData.description,
          name: this.formData.name,
        },
      })
      if (res?.status !== 200) return

      this.$emit('close')
      this.$emit('updated-item')
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = {
            name: newVal.attributes.name,
            description: newVal.attributes.description,
            client_id: newVal.attributes.clientId,
            invoiced_at: newVal.attributes.invoicedAt,
            date_from: newVal.attributes.dateFrom,
            date_to: newVal.attributes.dateTo,
            currency: newVal.attributes.currency,
            status: newVal.attributes.status,
          }
        }
      },
    },
  },
}
</script>
