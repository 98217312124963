<template>
  <div class="fields-container">
    <p class='error--text' v-if='errorMessages'>{{ $t('invoice.editModal.validationError') }}</p>
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('client_id')"
      :error-messages="(errorMessages && errorMessages.clientId) || []"
      class="modal-form__field"
      name="clientId"
      v-model="formData.client_id"
      :label="$t('invoice.fields.clientId')"
      disabled
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('name')"
      :error-messages="(errorMessages && errorMessages.name) || []"
      class="modal-form__field"
      name="name"
      v-model="formData.name"
      :label="$t('invoice.fields.name')"
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('description')"
      :error-messages="(errorMessages && errorMessages.description) || []"
      class="modal-form__field"
      name="description"
      v-model="formData.description"
      :label="$t('invoice.fields.description')"
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('invoiced_at')"
      :error-messages="(errorMessages && errorMessages.invoicedAt) || []"
      class="modal-form__field"
      name="invoiced_at"
      v-model="formData.invoiced_at"
      :label="$t('invoice.fields.invoicedAt')"
      disabled
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('date_from')"
      :error-messages="(errorMessages && errorMessages.dateFrom) || []"
      class="modal-form__field"
      name="date_from"
      v-model="formData.date_from"
      :label="$t('invoice.fields.dateFrom')"
      disabled
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('date_to')"
      :error-messages="(errorMessages && errorMessages.dateTo) || []"
      class="modal-form__field"
      name="date_to"
      v-model="formData.date_to"
      :label="$t('invoice.fields.dateTo')"
      disabled
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('currency')"
      :error-messages="(errorMessages && errorMessages.currency) || []"
      class="modal-form__field"
      name="currency"
      v-model="formData.currency"
      :label="$t('invoice.fields.currency')"
      disabled
    />
    <div class="break" />
    <hub-text-field
      @keyup="onInputFocus('status')"
      :error-messages="(errorMessages && errorMessages.status) || []"
      class="modal-form__field"
      name="status"
      v-model="formData.status"
      :label="$t('invoice.fields.status')"
      disabled
    />
  </div>
</template>

<script>
export default {
  name: 'InputFields',
  props: {
    formData: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    errorMessages() {
      return this.$store.getters['core/validationErrors']
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
  },
  methods: {
    onInputFocus(inputName) {
      this.removeError(inputName)
    },
    removeError(field) {
      this.$store.commit('core/removeError', field)
    },
  },
  watch: {},
}
</script>

<style>
.fields-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.fields-container > * {
  flex-grow: 2;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.hub-switch {
  margin: 0;
  height: 1.25rem;
}
</style>
