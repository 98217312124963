<template>
  <huboo-page>
    <v-container>
      <v-row class="align-center">
        <v-col cols="9">
          <huboo-page-heading :heading="$t('invoice.tablePageTitle') | title" />
        </v-col>
        <v-col cols="3">
          <v-btn color="primary" :disabled="syncButtonDisabled" @click="onSyncDraftInvoices">{{
            $t('invoice.syncDraftInvoicesButtonText')
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <huboo-table
      id="invoiceTable"
      @filter="handleFilter"
      @row-clicked="onSelected"
      @search="handleSearch"
      @update:options="handleUpdateOptions"
      @remove="openConfirmationModal"
      @edit="onEdit"
      @confirm-delete="onRemove"
      :filters="filters"
      :headers="headers"
      :items="items"
      :items-per-page="options.itemsPerPage"
      :server-items-length="meta.total"
      :loading="loading"
      clickable
      hide-search
      :remove="isAdmin"
      :edit="isAdmin"
      v-bind="options"
    >
      <template #filter>
        <huboo-filters
          :filters="filters"
          @filtersClear="onFiltersClear"
          @filtersApply="onApplyFilters"
          :activeFilters="activeFilters"
        >
          <v-col cols="2" sm="6" md="3">
            <v-select
              @change="fetch"
              :label="$t('invoice.fields.status')"
              :items="filteredStatuses"
              v-model="availableFilters.selectedStatus"
              dense
              outlined
              clearable
            />
          </v-col>
          <v-col cols="2" sm="6" md="3">
            <v-text-field
              dense
              outlined
              @change="fetch"
              :label="$t('invoice.fields.clientId')"
              v-model="availableFilters.selectedClient"
              clearable
            ></v-text-field>
          </v-col>
        </huboo-filters>
      </template>

      <!-- // leaving this commented out for now as this may be used in the future -->
      <!-- <template #actions>
        <v-btn :loading="createModalLoading" disabled color="primary" @click="openCreateModal">
          {{ $t('invoice.createModal.createButton') }}
        </v-btn>
      </template> -->
    </huboo-table>
    <invoice-edit-modal
      v-if="editModal"
      :value="editModal"
      :selected="selected"
      @close="onModalClose"
      @updated-item="onUpdatedItem"
    />
    <create-modal v-model="createModal" @close="closeCreateModal" @updated-item="onUpdatedItem" />
  </huboo-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { handleSearch } from '@/views/pages/billing/billingViewHelpers.js'
import { title } from '@/utilities/filters'
import { isLoading } from '@/utilities/helpers/views'
import ClientMixin from '@/mixins/client'
import filtersMixin from '@/mixins/filters'
import ModalActionsMixin from '@/mixins/modalActions'
import InvoiceEditModal from './InvoiceEditModal.vue'
import CreateModal from './InvoiceCreateModal.vue'
import { goTo } from '@/utilities/helpers/router'
import { InvoiceStatus } from './invoice.store'
import { formatAmount } from '@/modules/Invoice/helpers'

export default {
  name: 'Invoice',
  mixins: [ClientMixin, ModalActionsMixin, filtersMixin],
  components: {
    'invoice-edit-modal': InvoiceEditModal,
    'create-modal': CreateModal,
  },
  data: () => ({
    createModal: false,
    editModal: false,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    search: '',
    selected: null,
    activeFilters: 0,
    availableFilters: {
      selectedStatus: '',
      selectedClient: '',
    },
    syncingInvoice: false,
  }),
  computed: {
    ...mapGetters('core', ['getIsAdmin']),
    isAdmin() {
      return this.getIsAdmin
    },
    createModalLoading() {
      return isLoading(this.createUrl, 'POST')
    },
    createUrl() {
      return 'invoice/'
    },
    invoiceHeaders() {
      return [
        {
          text: title(this.$t('invoice.fields.id')),
          sortable: false,
          value: 'id',
        },
        {
          text: title(this.$t('invoice.fields.name')),
          sortable: false,
          value: 'attributes.name',
        },
        {
          text: title(this.$t('invoice.fields.invoicingPreference')),
          sortable: false,
          value: 'attributes.billingPreference',
        },
        {
          text: title(this.$t('invoice.fields.billingTotal')),
          sortable: false,
          value: 'attributes.grandTotal',
        },
        {
          text: title(this.$t('invoice.fields.clientId')),
          sortable: false,
          value: 'attributes.clientId',
        },
        {
          text: title(this.$t('invoice.fields.dateFrom')),
          sortable: true,
          value: 'attributes.dateFrom',
        },
        {
          text: title(this.$t('invoice.fields.dateTo')),
          sortable: true,
          value: 'attributes.dateTo',
        },
        {
          text: title(this.$t('invoice.fields.dueDate')),
          sortable: true,
          value: 'attributes.dueDate',
        },
        {
          text: title(this.$t('invoice.fields.postingDate')),
          sortable: true,
          value: 'attributes.invoicedAt',
        },
        {
          text: title(this.$t('invoice.fields.currency')),
          sortable: false,
          value: 'attributes.currency',
        },
        {
          text: title(this.$t('invoice.fields.status')),
          sortable: false,
          value: 'attributes.status',
        },
      ]
    },
    getInvoices() {
      return this.$store.getters['invoice/getAll']
    },
    getMeta() {
      return this.$store.getters['invoice/getMeta']
    },
    headers() {
      return this.invoiceHeaders
    },
    items() {
      return this.invoices.map(bh => {
        const isActionable = !(
          bh.attributes.status === InvoiceStatus.SYNCED ||
          bh.attributes.status === InvoiceStatus.SYNCING
        )
        return {
          ...bh,
          attributes: {
            ...bh.attributes,
            subTotal: formatAmount(bh.attributes.subTotal, bh.attributes.currency),
            vatTotal: formatAmount(bh.attributes.vatTotal, bh.attributes.currency),
            grandTotal: formatAmount(bh.attributes.grandTotal, bh.attributes.currency),
          },
          updatedAt: this.formatDate(bh.updatedAt),
          editable: isActionable,
          deletable: isActionable,
        }
      })
    },
    loading() {
      return isLoading(this.url)
    },
    meta() {
      return { total: this.getMeta.total || this.items.length }
    },
    filteredStatuses() {
      const statuses = this.$store.getters['invoice/getStatuses']
      return Object.values(statuses)
    },
    invoices() {
      const lists = this.getInvoices
      if (Array.isArray(lists))
        return lists.map(l => ({
          ...l,
          loading: this.removeLoading(l.id),
        }))
      else return []
    },
    url() {
      return this.urlEntity
    },
    urlEntity() {
      return '/invoice/'
    },
    draftInvoices() {
      return this.invoices.filter(invoice => invoice.attributes.status === InvoiceStatus.DRAFT)
    },
    syncButtonDisabled() {
      return this.syncingInvoice || this.draftInvoices.length === 0 || !this.isAdmin
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    ...mapActions('invoice', ['syncAllInvoices']),
    goTo,
    fetch() {
      const { options, search } = this
      const { page, itemsPerPage, sortBy, sortDesc } = options

      const { selectedStatus, selectedClient } = this.availableFilters

      let params = {}
      if (page) params.page = page
      if (itemsPerPage) params.itemsPerPage = itemsPerPage
      if (search) params.query = search
      if (selectedStatus) {
        params = { ...params, 'filter[status]': selectedStatus }
      }
      if (selectedClient) {
        params = { ...params, 'filter[client]': selectedClient }
      }
      if (sortBy.length && sortDesc.length) {
        const direction = sortDesc[0] ? 'desc' : 'asc'
        const field = sortBy[0].replace('attributes.', '')
        params.sort = `${direction === 'desc' ? '-' : ''}${field}`
      }

      return this.$store.dispatch('invoice/fetchCollection', { params })
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    async onRemove() {
      await this.$store.dispatch('invoice/delete', this.selected.id)
    },
    openConfirmationModal(selectedRow) {
      this.selected = selectedRow
    },
    onSelected(e) {
      this.goTo({
        name: 'InvoiceSummary',
        params: { invoiceId: e.id },
      })
    },
    onEdit(e) {
      // Guard to ensure that edit modal is not opened if no event.
      if (!e) return
      const selectedArray = Object.entries(e)
      const stringToBool = selectedArray.map(item => {
        if (item[1] === 'Yes') {
          return [item[0], true]
        }
        if (item[1] === 'No') {
          return [item[0], false]
        } else {
          return item
        }
      })
      this.selected = stringToBool.reduce((acc, item) => {
        acc[item[0]] = item[1]
        return acc
      }, {})
      this.editModal = true
    },
    onModalClose() {
      this.editModal = false
      this.selected = null
    },
    onUpdatedItem() {
      this.fetch()
    },

    removeLoading(id) {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: this.urlEntity + id,
        method: 'DELETE',
      })
    },
    onFiltersClear() {
      this.clearFilters(this.availableFilters)
      this.activeFilters = 0
    },
    onApplyFilters() {
      this.applyFilters(this.availableFilters)
    },
    async onSyncDraftInvoices() {
      if (this.syncButtonDisabled) return

      this.syncingInvoice = true
      try {
        await this.syncAllInvoices()
        await this.fetch()
      } catch (e) {
        console.error(e)
      } finally {
        this.syncingInvoice = false
      }
    },
    handleUpdateOptions(newOptions) {
      this.options = {
        ...this.options,
        page: newOptions.page,
        itemsPerPage: newOptions.itemsPerPage,
        sortBy: newOptions.sortBy,
        sortDesc: newOptions.sortDesc,
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
